<template>
  <div>
    <v-simple-table class="table mt-2" height="81vh">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Tag Name</th>
            <th class="text-left">Hidden</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in tags" :key="i">
            <td>{{ item.name }}</td>
            <td>{{ item.app_hidden ? 'true' : 'false' }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="blue" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="error" v-bind="attrs" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="green" v-bind="attrs" v-on="on">
                      <v-icon>mdi-account-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Impersonate</span>
                </v-tooltip> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "tags",
  data() {
    return {
      tags: [
        {
          app_hidden: true,
          fire_id: "Hysnz7ddK6UvrPivVcx1oYe22Ic2",
          name: "Consumable",
          source: "app",
          status: 1,
          _id: "60347aa05254b10008fc60eb",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
</style>
